import '../App.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Slider from "./Slider";
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import FemaleIcon from '@mui/icons-material/Female';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import { Link } from 'react-router-dom';
import VideocamIcon from '@mui/icons-material/Videocam';

function Main() {
    return (
      <div className="App">
        <Box className="main-grid-container">
          <Grid container columns={{ xs: 4, md: 12 }} sx={{ mr: '10%', ml: '10%', mt: '5%', mb: '5%' }} spacing={1}>

            <Grid xs={4}>
              <Box sx={{ textAlign: 'left' }}>
                <Typography variant="h5" gutterBottom>
                  Stop Gender Ideology
                </Typography>
                <Typography variant="body1" gutterBottom> 
                  Welcome! My name is Erin Lee. 
                  I’m an awakened Mama whose little girl 
                  was harmed by gender ideology in her public school.
                  My mission is to educate others, set precedent,
                  help other parents of ROGD children, and shift our culture
                  away from dangerous gender ideology being perpetuated by adults onto our kids.
                </Typography>
              </Box>
            </Grid>
            <Grid xs={8}>
              <Slider />
            </Grid>

          </Grid>
         
          <Grid container columns={{ xs: 4, md: 12 }} sx={{ mt: '5%', ml: '20%', mr: '20%' }} spacing={1}>
            <Grid xs={4}>
              <Link to="https://www.givesendgo.com/stopgenderideology" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                <Paper sx={{ ':hover': { boxShadow: 5 }, textAlign: 'center', minHeight: '100%' }}>
                  <Typography sx={{ p: '1rem' }} variant="h6" gutterBottom>
                    Donate
                  </Typography>
                  <VolunteerActivismIcon color="primary" sx={{ fontSize: '3rem' }}/>
                  <Typography sx={{ p: '1rem' }} variant="body1" gutterBottom>
                    We can't do this alone. We're grateful for your support.
                  </Typography>
                </Paper>
              </Link>
            </Grid>
            <Grid xs={4}>
              <Link to="https://www.ArtClubMovie.com" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                <Paper sx={{ ':hover': { boxShadow: 5 }, textAlign: 'center', minHeight: '100%' }}>
                  <Typography sx={{ p: '1rem' }} variant="h6" gutterBottom>
                    Documentary
                  </Typography>
                  <VideocamIcon color="primary" sx={{ fontSize: '3rem' }}/>
                  <Typography sx={{ p: '1rem' }} variant="body1" gutterBottom>
                    We can't do this alone. We're grateful for your support.
                  </Typography>
                </Paper>
              </Link>
            </Grid>
            
            <Grid xs={4}>
              <Link to="/Resources" style={{ textDecoration: 'none' }}>
                <Paper sx={{ ':hover': { boxShadow: 5 }, textAlign: 'center', minHeight: '100%' }}>
                  <Typography sx={{ p: '1rem' }} variant="h6" gutterBottom>
                    Resources
                  </Typography>
                  <TravelExploreIcon color="primary" sx={{ fontSize: '3rem' }}/>
                  <Typography sx={{ p: '1rem' }} variant="body1" gutterBottom>
                      Click here to access resources for protecting your children
                  </Typography>
                </Paper>
              </Link>
            </Grid>
            <Grid xs={4}>
              <Link to="/RapidOnset" style={{ textDecoration: 'none' }}>
                <Paper sx={{ ':hover': { boxShadow: 5 }, textAlign: 'center', minHeight: '100%' }}>
                  <Typography sx={{ p: '1rem' }} variant="h6" gutterBottom>
                    ROGD
                  </Typography>
                  <FemaleIcon color="primary"  sx={{ fontSize: '3rem' }}/>
                  <Typography sx={{ p: '1rem' }} variant="body1" gutterBottom>
                      Resources for rescuing your child from rapid onset gender dysphoria
                  </Typography>
                </Paper>
              </Link>
            </Grid>
            <Grid xs={4}>
              <Link to="/Story" style={{ textDecoration: 'none' }}>
                <Paper sx={{ ':hover': { boxShadow: 5 }, textAlign: 'center', minHeight: '100%' }}>
                  <Typography sx={{ p: '1rem' }} variant="h6" gutterBottom>
                    Our Story
                  </Typography>
                  <AutoStoriesIcon color="primary" sx={{ fontSize: '3rem' }}/>
                  <Typography sx={{ p: '1rem' }} variant="body1" gutterBottom>
                    Learn about our family's experience with gender ideology
                  </Typography>
                </Paper>
              </Link>
            </Grid>
            <Grid xs={4}>
              <Link to="/Connect" style={{ textDecoration: 'none' }}>
                <Paper sx={{ ':hover': { boxShadow: 5 }, textAlign: 'center', minHeight: '100%' }}>
                  <Typography sx={{ p: '1rem' }} variant="h6" gutterBottom>
                    Connect
                  </Typography>
                  <ConnectWithoutContactIcon color="primary"  sx={{ fontSize: '3rem' }}/>
                  <Typography sx={{ p: '1rem' }} variant="body1" gutterBottom>
                      Connect with us for more information
                  </Typography>
                </Paper>
              </Link>
            </Grid>
          </Grid>
        </Box>

      </div>
      
    );
  }
  
  export default Main;