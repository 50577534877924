import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Link } from 'react-router-dom';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="tab-container" sx={{ p: 3}}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Resources() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      
      <Box minWidth={'50%'} m={5} centered>
        <Typography variant="h5" gutterBottom>
          <Box component="span" fontWeight="bold">
            Resources
          </Box>
        </Typography>
      </Box>
      
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs variant="scrollable" scrollButtons="auto" value={value} onChange={handleChange} aria-label="ROGD Tabs">
          <Tab label="Advice" {...a11yProps(0)} />
          <Tab label="Resources" {...a11yProps(1)} />
          <Tab label="Literature" {...a11yProps(2)} />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>

          <Box className="tab-box" >
            
            <List sx={{ listStyleType: 'disc' }}>

                <ListItem>
                  <Typography variant="body" fontWeight="bold">
                      3 things to tell your kids:
                  </Typography>      
                </ListItem>
                <br/>
                <ListItem sx={{ display: 'list-item' }}>#1 - You are perfectly & wonderfully made. God doesn’t make mistakes.</ListItem>
                <ListItem sx={{ display: 'list-item' }}>#2 - Safe adults don’t ask you to keep secrets. (Doesn’t matter how small the secret seems, always tell your parents.) </ListItem>
                <ListItem sx={{ display: 'list-item' }}>#3 - No one should ask you to label yourself. (This includes pronouns.) You are just perfectly & uniquely YOU; no label </ListItem>
                <br/>
                <ListItem>
                  <Typography variant="body" fontWeight="bold">
                      What to keep an eye on:
                  </Typography>
                </ListItem>
                <br/>
                <ListItem sx={{ display: 'list-item' }}>Social Emotional Learning (SEL)</ListItem>
                <ListItem sx={{ display: 'list-item' }}>Surveys (Healthy Kids Colorado survey)</ListItem>
                <ListItem sx={{ display: 'list-item' }}>Human Growth & Development</ListItem>
                <ListItem sx={{ display: 'list-item' }}>School Counselors office & Mental Health </ListItem>
                <ListItem sx={{ display: 'list-item' }}>Bullying & Suicide Prevention programs </ListItem>
                <ListItem sx={{ display: 'list-item' }}>All Curriculum: watch reading assignments</ListItem>
                <ListItem sx={{ display: 'list-item' }}>Teacher/Staff trainings (what are they learning & who is teaching them?)</ListItem>
                <ListItem sx={{ display: 'list-item' }}>Outside party involvement</ListItem>

            </List>
          </Box>


      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        
        <Box  className="tab-box" >
          <List sx={{ listStyleType: 'disc' }}>
            <ListItem sx={{ display: 'list-item' }}> <Link target="_blank" rel="noreferrer" to="https://defendinged.org/expose/">Expose Incidents Here - Parents Defending Education</Link></ListItem>
            <ListItem sx={{ display: 'list-item' }}> <Link target="_blank" rel="noreferrer" to="https://defendinged.org/map/">See what has already been exposed in your district - Parents Defending Education</Link></ListItem>
            <ListItem sx={{ display: 'list-item' }}> <Link target="_blank" rel="noreferrer" to="https://defendinged.org/expose/">Is there objectionable content in your child's reading assignment? - Check Here</Link></ListItem>
            <ListItem sx={{ display: 'list-item' }}> <Link target="_blank" rel="noreferrer" to="https://www.comprehensivesexualityeducation.org/sample-opt-out-forms/">Opt Out Forms</Link></ListItem>
          </List>
        </Box>

      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>

        <Box  className="tab-box" >
          <List sx={{ listStyleType: 'disc' }}>
            <iframe title="book1" type="text/html" sandbox="allow-scripts allow-same-origin allow-popups" width="336" height="550" allowFullScreen src="https://read.amazon.com/kp/card?asin=B09G6TJ6KR&preview=inline&linkCode=kpe&ref_=cm_sw_r_kb_dp_V41F5NHEV08QH97E3PFA" ></iframe>
            <iframe title="book1" type="text/html" sandbox="allow-scripts allow-same-origin allow-popups" width="336" height="550" allowFullScreen src="https://read.amazon.com/kp/card?asin=B0B5SD5SCM&preview=inline&linkCode=kpe&ref_=cm_sw_r_kb_dp_4ZVXBESHHGYCSC431FR1" ></iframe>
          </List>
        </Box>

      </CustomTabPanel>
    </Box>
  );
}