import React, { useState, useRef } from "react";
import { TextField, Button, Typography, Box } from "@mui/material";
import emailjs from '@emailjs/browser';
import LoadingButton from '@mui/lab/LoadingButton';

export default function ContactForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const form = useRef();
  const [buttonText, setbuttonText] = useState("Submit");
  const [loading, setLoading] = React.useState(false);
  const [buttonColor, setButtonColor] = useState("primary");

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs.sendForm('service_yl5l24n', 'template_a0d85zw', form.current, 'gsyCSLc3G4faRb5nQ')
      .then((result) => {
        setLoading(false);
        setButtonColor("success");
        setbuttonText("Success");
      }, (error) => {
        setLoading(false);
        setButtonColor("error");
        setbuttonText("Error");
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
      }}
    >
      <Box sx={{ maxWidth: 600, mx: "auto", p: 2 }}>
        <Typography variant="h4" align="center" mb={2}>
          Join the fight
        </Typography>
        <Typography variant="body" align="center" mb={2}>
          Please fill out the form below to be added to our mailing list
        </Typography>
        <form ref={form} onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
            required
            name="from_name"
          />
          <TextField
            fullWidth
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            required
            type="email"
            name="user_email"
          />
          <TextField
            fullWidth
            label="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            margin="normal"
            required
            multiline
            rows={4}
            name="message"
          />
          <LoadingButton loading={loading} variant="contained" type="submit" color={buttonColor} sx={{ mt: 2 }}>
            {buttonText}
          </LoadingButton>
        </form>
      </Box>
    </Box>
  );
}