import Paper from '@mui/material/Paper';
import { SocialIcon } from 'react-social-icons';
import BottomNavigation from '@mui/material/BottomNavigation';

function Footer() {
    return (

        <Paper className="footer-social" sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, pt: '0' }} elevation={3}>
            <BottomNavigation>
              <SocialIcon url="https://www.facebook.com/erinforparentalrights" style={{ margin: '1%', height: 40, width: 40 }} />
              <SocialIcon url="https://twitter.com/Erin4Parents" style={{ margin: '1%',height: 40, width: 40 }} />
              <SocialIcon url="https://www.instagram.com/erinforparentalrights" style={{ margin: '1%',height: 40, width: 40 }} />
              <SocialIcon url="https://youtu.be/MXpR4h1Z6J0?si=8Yqb9R1xiAupImjQ" network="youtube" style={{ margin: '1%', height: 40, width: 40 }} />
              <SocialIcon url="mailto:erin20lee@gmail.com" network="mailto" style={{ margin: '1%',height: 40, width: 40 }} />
            </BottomNavigation>
        </Paper>

    );
  }
  
  export default Footer;