import First from "../assets/erinWinsome.jpg";
import Second from "../assets/erinHeidiGreen.jpg";
import Third from "../assets/erinPowerPoint.jpg";
import Fourth from "../assets/awardMusin.jpg";
import Fifth from "../assets/speechPodium.jpg";
import Sixth from "../assets/erinAndJonCPAN.jpg";

export default [
  {
    title: "",
    description: "",
    urls: First,
  },
  {
    title: "",
    description: "",
    urls: Second,
  },
  {
    title: "",
    description: "",
    urls: Third,
  },
  {
    title: "",
    description: "",
    urls: Fourth,
  },
  {
    title: "",
    description: "",
    urls: Fifth,
  },
  {
    title: "",
    description: "",
    urls: Sixth,
  }
];
