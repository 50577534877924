import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Link } from 'react-router-dom';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="tab-container" sx={{ p: 3}}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function RapidOnset() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      
      <Box minWidth={'50%'} m={5} centered>
        <Typography variant="h5" gutterBottom>
          <Box component="span" fontWeight="bold">
            Your child suddenly says they’re trans. Now what?
          </Box>
        </Typography>
        <Typography variant="h5" gutterBottom>
          <Box component="span" fontWeight="bold">
            You're not alone.
          </Box>
        </Typography>
      </Box>
      
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs variant="scrollable" scrollButtons="auto" value={value} onChange={handleChange} aria-label="ROGD Tabs">
          <Tab label="Advice" {...a11yProps(0)} />
          <Tab label="Resources" {...a11yProps(1)} />
          <Tab label="Literature" {...a11yProps(2)} />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>

          <Box className="tab-box" >
            
            <List sx={{ listStyleType: 'disc' }}>
            <ListItem>
              <Typography variant="body" fontWeight="bold">
                  In hindsight, here are the things that helped our family successfully navigate through the nightmare and led our daughter to completely desist trans identity: 
              </Typography>      
            </ListItem>

              <ListItem sx={{ display: 'list-item' }}>Stay calm for your child’s sake. Trust your instinct; you know your child best.</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Monitor & Restrict technology. What are their negative online influences: TikTok? Discord? Deviantart.com? Cut off the toxicity.</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Remove the negative influences - Replace them with positive ways to spend time. </ListItem>
              <ListItem sx={{ display: 'list-item' }}>Be careful with therapists. Meet them first and ask lots of questions. Trust your gut. The mental health field and many state legislatures have been hijacked. In Colorado, where I live, 12-year-olds steward their own mental healthcare and gender conversion therapy law prohibits anything except affirming trans confusion. </ListItem>
              <ListItem sx={{ display: 'list-item' }}> Be subtle with educating your child on the dangers & falsities of gender ideology… Then, when the time is right, be direct about it. </ListItem>

            </List>
          </Box>


      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        
        <Box className="tab-box" >
          <List sx={{ listStyleType: 'disc' }}>
              <ListItem sx={{ display: 'list-item' }}> <Link target="_blank" rel="noreferrer" to="https://ourduty.group/">Our Duty</Link></ListItem>
              <ListItem sx={{ display: 'list-item' }}> <Link target="_blank" rel="noreferrer" to="https://pitt.substack.com/">Parents with Inconvenient Truths about Trans (PITT)</Link></ListItem>
              <ListItem sx={{ display: 'list-item' }}> <Link target="_blank" rel="noreferrer" to="https://www.partnersforethicalcare.com/">Partners For Ethical Care</Link></ListItem>
              <ListItem sx={{ display: 'list-item' }}> <Link target="_blank" rel="noreferrer" to="https://www.iwf.org/identity-crisis/">Independent Women’s Forum</Link></ListItem>
              <ListItem sx={{ display: 'list-item' }}> <Link target="_blank" rel="noreferrer" to="https://www.focusonthefamily.com/get-help/transgender-resources/">Focus on the Family</Link></ListItem>
              <ListItem sx={{ display: 'list-item' }}> <Link target="_blank" rel="noreferrer" to="http://www.genspect.org/">Genspect</Link></ListItem>
              <ListItem sx={{ display: 'list-item' }}> <Link target="_blank" rel="noreferrer" to="https://www.advocatesprotectingchildren.org/">Advocates Protecting Children</Link></ListItem>
          </List>
        </Box>

      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>

        <Box className="tab-box">
          <List sx={{ listStyleType: 'disc' }}>
            <iframe title="book1" type="text/html" sandbox="allow-scripts allow-same-origin allow-popups" width="336" height="550" allowFullScreen src="https://read.amazon.com/kp/card?asin=B0BR4FDB44&preview=inline&linkCode=kpe&ref_=cm_sw_r_kb_dp_FQHK2AERGARNQCNHWE6Y" ></iframe>
            <iframe title="book2" type="text/html" sandbox="allow-scripts allow-same-origin allow-popups" width="336" height="550" allowFullScreen src="https://read.amazon.com/kp/card?asin=B07YL6XK55&preview=inline&linkCode=kpe&ref_=cm_sw_r_kb_dp_4DTK22H257RHS51MSF6R" ></iframe>
            <iframe title="book3" type="text/html" sandbox="allow-scripts allow-same-origin allow-popups" width="336" height="550" allowFullScreen src="https://read.amazon.com/kp/card?asin=B0C759GZFP&preview=inline&linkCode=kpe&ref_=cm_sw_r_kb_dp_2Z6766JXT9PJT1PJC3RQ" ></iframe>
            <iframe title="book4" type="text/html" sandbox="allow-scripts allow-same-origin allow-popups" width="336" height="550" allowFullScreen src="https://read.amazon.com/kp/card?asin=B0923B6LNG&preview=inline&linkCode=kpe&ref_=cm_sw_r_kb_dp_TR1KBDY7A8V06E5F6RJ0" ></iframe>
            <iframe title="book5" type="text/html" sandbox="allow-scripts allow-same-origin allow-popups" width="336" height="550" allowFullScreen src="https://read.amazon.com/kp/card?asin=B0CG6S677B&preview=inline&linkCode=kpe&ref_=cm_sw_r_kb_dp_HF1YHT0MZ5AJG7F188FB" ></iframe>
          </List>
        </Box>

      </CustomTabPanel>
    </Box>
  );
}