import Typography from '@mui/material/Typography';
import Sixth from "../assets/iMeanBusiness.jpg";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';

function About() {
    return (
      <div className="About">
        <Box>
          <Grid container columns={{ xs: 4, md: 12 }} sx={{ mt: '5%' }} spacing={1}>
            <Grid xs={6}>
              <img style={{height:'85%', width:'85%'}} className="slide-image" src={Sixth} alt="" />
            </Grid>
            <Grid xs={6}>
              <Box sx={{ textAlign: 'left' }}>
                <Typography sx={{ pt: '' }} variant="body1" gutterBottom>
                  &emsp;Erin Lee is a full-time working Mom of three in Northern Colorado, a proud former Army wife, Writer, Speaker, and the Founder of stopgenderideology.com. 
                </Typography><br/>
                <Typography sx={{ pt: '' }} variant="body1" gutterBottom>
                &emsp;She and her husband have become vocal advocates for parental rights after their little girl was recruited by her trusted Art teacher into a secret gender & sexuality meeting disguised as “Art Club” where their daughter was convinced that her normal 12-year-old discomfort means she is transgender. In this club, the teacher and an outside activist discussed attraction, polyamory, puberty blockers, and suicide. They positioned themselves as “safe adults,” gave the kids toys, and provided a cell phone number to reach out to privately. They also told the children on multiple occasions that their families might not be safe, and it’s okay to lie to parents.                     
                </Typography><br/>
                <Typography sx={{ pt: '' }} variant="body1" gutterBottom>
                &emsp;Since rescuing her daughter from the throws of transgender confusion and the harms of predacious activists, Erin is dedicated to informing others about the very real nightmare of gender ideology that has permeated our schools, our healthcare systems, the media, our culture, even our White House. 
                </Typography><br/>
                <Typography sx={{ pt: '' }} variant="body1" gutterBottom>
                &emsp;With her daughter’s complete support, she now uses their story to help other families and has connected with 100’s of families who’ve also been affected by gender ideology & public school secrecy. 
                </Typography><br/>
                <Typography sx={{ pt: '' }} variant="body1" gutterBottom>
                &emsp;Erin has told her story on Fox News, NewsMax, Glenn Beck, the EpochTimes, Libs of TikTok, and the DailyMail UK. She has testified in multiple states for pro-transparency, pro-family involvement legislation and against the gender mutilation of children. She and another family have also filed a federal lawsuit against their school district with former FL Attorney General, Pam Bondi and America First Policy Institute leading their legal team. 
                </Typography><br/>
                <Typography sx={{ pt: '' }} variant="body1" gutterBottom>
                &emsp;Erin recently contributed to Dr. Miriam Grossman’s new book “Lost in Trans Nation” and can be seen in the upcoming feature documentary “Dysphoria” by Fearless Features. She is also working with former CO Senator, Kevin Lundberg, on a documentary film project called “Art Club” which will be released this year - stay tuned!          
                </Typography><br/><br/><br/><br/>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
      
    );
  }
  
  export default About;