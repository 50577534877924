import './App.css';
import { getAppInsights } from '../src/components/TelemetryService';
import TelemetryProvider from '../src/components/telemetry-provider';
import SearchAppBar from '../src/NavBar.js';
import {Route, Routes} from 'react-router-dom';
import Main from '../src/components/Main.js';
import Resources from '../src/components/Resources.js';
import Connect from '../src/components/Connect.js';
import About from '../src/components/About.js';
import Story from '../src/components/Story.js';
import Footer from '../src/components/Footer.js';
import RapidOnset from '../src/components/RapidOnset.js';

function App() {
  let appInsights = null;
  return (
    <div className="App">
      <SearchAppBar/>
        <TelemetryProvider instrumentationKey={process.env.APPINSIGHTS_INSTRUMENTATIONKEY} after={() => { appInsights = getAppInsights() }}>
          <Routes>
            <Route path='/' element={ <Main /> } />
            <Route path='/Resources' element={ <Resources /> } />
            <Route path='/Story' element={ <Story /> } />
            <Route path='/Connect' element={ <Connect /> } />
            <Route path='/About' element={ <About /> } />
            <Route path='/RapidOnset' element={ <RapidOnset /> } />
          </Routes>
        </TelemetryProvider>
      <Footer/>
    </div>    
  );
}

export default App;
