import '../App.css';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Link } from 'react-router-dom';

function Story() {
    return (
      <div className="our-story">
        <div className="container-video">
            <iframe className="responsive-iframe" src="https://rumble.com/embed/v29en3q/?pub=4" allowFullScreen></iframe>
        </div>

        <Box sx={{ marginLeft: '1rem', marginRight: '1rem', marginBottom: '1rem' }}>

          <Typography sx={{ marginTop: '2rem' }}><Box component="span" fontWeight="bold">Videos</Box></Typography>

          <List sx={{ listStyleType: 'disc' }}>
              <ListItem sx={{ display: 'list-item' }}> <Link target="_blank" rel="noreferrer" to="https://www.glennbeck.com/radio/mom-my-kid-was-coerced-into-secret-sexuality-club-at-school">Glenn Beck | Mom: My kid was COERCED into SECRET sexuality club at school</Link></ListItem>
              <ListItem sx={{ display: 'list-item' }}> <Link target="_blank" rel="noreferrer" to="https://www.foxnews.com/media/colorado-mom-sues-school-sixth-graders-secret-school-gender-sexuality-club">Fox News | Colorado mom sues school that recruited sixth-graders for secret after-school gender and sexuality club</Link></ListItem>
              <ListItem sx={{ display: 'list-item' }}> <Link target="_blank" rel="noreferrer" to="https://www.youtube.com/watch?v=dxr-hkWTyw0">News Max | National Report | Parents suing school district over secret LGBTQ club</Link></ListItem>
              <ListItem sx={{ display: 'list-item' }}> <Link target="_blank" rel="noreferrer" to="https://americasvoice.news/video/68686/">Real America's Voice News | LOST IN TRANS NATION</Link></ListItem>
              <ListItem sx={{ display: 'list-item' }}> <Link target="_blank" rel="noreferrer" to="https://www.youtube.com/watch?v=CLjmE7GhsHI">Jon Caldara | Gender & Sexuality Art Club</Link></ListItem>   
          </List>

          <Typography sx={{ marginTop: '2rem' }}><Box component="span" fontWeight="bold">Articles</Box></Typography>

          <List sx={{ listStyleType: 'disc' }}>
            <ListItem sx={{ display: 'list-item' }}> <Link target="_blank" rel="noreferrer" to="https://defendinged.org/incidents/12-year-old-student-invited-to-afterschool-art-club-finds-herself-in-a-meeting-about-trans-and-queer-identity-where-she-is-told-to-keep-secrets-and-that-parents-arent-safe/">Parents Defending Education | 12-year-old student invited to after-school art club</Link></ListItem>
            <ListItem sx={{ display: 'list-item' }}> <Link target="_blank" rel="noreferrer" to="https://link.theepochtimes.com/mkt_app/us/after-scandal-schools-use-witch-to-address-lgbt-issues-5047293?utm_medium=app&c=share_pos2&pid=iOS_app_share&utm_source=iOS_app_share">Epoch Times | After Scandal, Schools Use ‘Witch’ to Address LGBT Issues</Link></ListItem>
            <ListItem sx={{ display: 'list-item' }}> <Link target="_blank" rel="noreferrer" to="https://wpde.com/renderer/wpde/amp/news/nation-world/parents-sue-colorado-school-district-say-daughter-was-deceived-into-discussing-gender-transition">Crisis in the Classroom | 'Stole her innocence': Parents sue school district, say daughter was 'deceived' into discussing gender transition</Link></ListItem>
            <ListItem sx={{ display: 'list-item' }}> <Link target="_blank" rel="noreferrer" to="https://www.foxnews.com/media/daughter-meeting-queer-transgender-identity-art-club">Fox News | Mom says presenter at daughter's supposed art club asked of sexual attraction, suggested she was transgender</Link></ListItem>
            <ListItem sx={{ display: 'list-item' }}> <Link target="_blank" rel="noreferrer" to="https://www.dailymail.co.uk/news/article-10809457/Colorado-mom-says-daughter-invited-high-school-art-club-actually-transgender-group.html">Daily Mail | Fury as Colorado teacher invites schoolgirl, 12, to after-school arts club that was actually a meeting about trans and queer identity</Link></ListItem>
            <ListItem sx={{ display: 'list-item' }}> <Link target="_blank" rel="noreferrer" to="https://www.charismanews.com/culture/92422-a-bright-light-that-shines-on-a-dark-scheme-to-sexualize-children">Charisma News | A Bright Light That Shines on a Dark Scheme to Sexualize Children</Link></ListItem>
            <ListItem sx={{ display: 'list-item' }}> <Link target="_blank" rel="noreferrer" to="https://www.coloradoan.com/story/news/2022/05/24/ppoudre-school-district-fort-collins-broke-ties-splash-noco-following-parent-complaint-club/9824434002/">The Coloradoan | PSD broke ties with LGBTQ+ group following parent complaint about club</Link></ListItem>       
          </List>
        </Box>
      </div>
      
    );
  }
  
  export default Story;